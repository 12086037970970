import { httpsCallable } from 'firebase/functions';
import functionsNav from './firebaseNavconfig';
import { Product, WarehouseHeader, FunctionResponse } from './interfaces';
import { v4 as uuidv4 } from 'uuid';

interface FetchFunctionProps {
  noBl: string;
  warehouseHeaders: WarehouseHeader[];
  setWarehouseHeaders: React.Dispatch<React.SetStateAction<WarehouseHeader[]>>;
  setItems: React.Dispatch<React.SetStateAction<Product[]>>;
  items: Product[];
}


const generateUniqueId = (items: Product[]): string => {
  let uniqueId: string;

  do {
    uniqueId = uuidv4();
  } while (items.some(item => item.id === uniqueId));
  console.log("Create uniqueId", uniqueId)
  return uniqueId;
};

const fetchFunction = async ({
  noBl,
  warehouseHeaders,
  setWarehouseHeaders,
  setItems,
  items
}: FetchFunctionProps): Promise<WarehouseHeader | false> => {


  function formatCodeEAN(codeEAN: string): string {
    const maxLength = 13;
    const currentLength = codeEAN.length;
    
    if (currentLength < maxLength) {
        const numberOfZerosToAdd = maxLength - currentLength;
        const zeros = '0'.repeat(numberOfZerosToAdd);
        return zeros + codeEAN;
    }
    
    return codeEAN;
}

  if (!noBl) return false;

  if (warehouseHeaders.some(header => header.No === noBl)) {
    return warehouseHeaders.find(header => header.No === noBl) || false;
  }

  const getPrepOrderList = httpsCallable<{ noBl: string }, FunctionResponse>(functionsNav, 'getPrepOrderList');
  try {
    const functionResponse = await getPrepOrderList({ noBl });
    const warehouseHeader = functionResponse.data.data;

    if (!warehouseHeader) {
      return false;
    } else {
      const updatedProducts = warehouseHeader.products.map(product => {
        const uniqueId = generateUniqueId(items);
        const updatedProduct: Product = {
          ...product,
          id: uniqueId,
          Quantity: product.Qty_to_Ship ,
          ActualQuantity: product.Quantity,
          PreviousActualQuantity: product.Quantity,
          Destination_No: product.Destination_No,
          Bin_Code: product.Bin_Code,
          External_Document_No: warehouseHeader.External_Document_No,
          Code_EAN: formatCodeEAN(product.Code_EAN)
        };
        return updatedProduct;
      });

      warehouseHeader.products = updatedProducts;
      return warehouseHeader;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return false;
  }
};

export default fetchFunction;
