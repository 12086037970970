import React, { useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Tooltip,
  Button,
  Modal
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import QuantitiesIcon from '@mui/icons-material/BarChart';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppContext } from './AppContext';
import {Product} from './interfaces';
import * as Tone from 'tone';
// import saveAsPdf from './SaveToPdf';
import generatePdfWithPagination from './generatePdfWithPagination';
import { useAuth } from './Context/AuthContext';
type Carton = { name: string, items: Product[], totalPoidsCarton?: number; };
type Palette = { name: string, items: (Product | Carton)[] };


const playSuccessSound = () => {
  const synth = new Tone.Synth().toDestination();
  synth.triggerAttackRelease("E4", "8n");
};

const Header: React.FC = () => {
  const {  warehouseHeaders, items, handleReset,allContent, setAllContent, paletteLists, cartonLists, showUnitCost} = useAppContext();
  const [totalInitialQuantity, setTotalInitialQuantity] = useState(0);
  const [totalUpdatedQuantity, setTotalUpdatedQuantity] = useState(0);
  const [progressionFigures, setProgressionFigures] = useState(0);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  let successSoundPlayed = false;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
  };


  
  useEffect(() => {
    const initialQuantity = items.reduce((total, item) => total + item.Quantity, 0);
    setTotalInitialQuantity(initialQuantity);
  }, [items]);


  useEffect(() => {
    const updatedQuantity = items.reduce((total, item) => total + item.ActualQuantity, 0);
    setTotalUpdatedQuantity(updatedQuantity);
  }, [items]);


  useEffect(() => {
    if (totalInitialQuantity === 0) {
      setProgressionFigures(0);
    } else {
      const currentProgress = (totalUpdatedQuantity / totalInitialQuantity) * 100;
      const progressFigure = parseFloat((100 - currentProgress).toFixed(2));
      setProgressionFigures(progressFigure);
    }
  }, [totalInitialQuantity, totalUpdatedQuantity]);

  const statistics = useMemo(() => {
    
    const uniqueNoBls = new Set(warehouseHeaders.map(header => header.No)).size;
    const uniqueItems = new Set(items.map(item => item.Code_EAN)).size;
    const totalQuantity = items.reduce((total, item) => total + item.Quantity, 0);
    return {
      bpCount: uniqueNoBls,
      itemCount: uniqueItems,
      totalQuantity,
    };
  }, [items, warehouseHeaders]);

  const CalculateTotalProductWithoutCarton = (paletteList: Palette[]): number => {
    let totalItemCount = 0;

    paletteList.forEach((palette) => {
        const matchingItems = palette.items.filter(item => 'name' in item && item.name.startsWith("Produits sans carton"));

        matchingItems.forEach((content) => {
            if ('items' in content) {
                content.items.forEach((item) => {
                    totalItemCount +=  item.Qty_to_Ship;
                    console.log("Item:", item);
                });
            }
        });
    });

    console.log("Total items count:", totalItemCount);

    return totalItemCount;
}


    

  const calculateTotalProducts = (cartonLists?: { name: string, items: Product[] }[], paletteLists?: { name: string, items: (Product | { name: string, items: Product[] })[] }[]) => {
    let totalProducts = 0;
  
    if (cartonLists) {
      cartonLists.forEach(carton => {
      totalProducts += carton.items.reduce((sum, item) => sum + item.Quantity, 0);
    });
  }
  
    if (paletteLists) {
      paletteLists.forEach(palette => {
    if (palette.items && Array.isArray(palette.items)) {
      palette.items.forEach(item => {
    if ('items' in item) {
      totalProducts += item.items.reduce((sum, subItem) => sum + subItem.Quantity, 0);
    } else {
      totalProducts += item.Quantity;
    }
    });
    }
    });
    }
      return totalProducts;
  };
  
  const calculateTotalCartonQuantity = (
    cartonLists?: Carton[], 
    paletteLists?: Palette[]
  ): number => {
    if (!cartonLists || !paletteLists) return 0;
  
    let totalQuantity = 0;
  
    cartonLists.forEach(carton => {
      totalQuantity += 1;
    });
  
    paletteLists.forEach(palette => {
      if (palette.items && Array.isArray(palette.items)) {
        palette.items.forEach(item => {
          if ('items' in item && !item.name.startsWith('Produits sans carton')) {
            totalQuantity += 1;
          }
        });
      }
    });
  
    return totalQuantity;
  };
  

  const totalCartonQuantity = calculateTotalCartonQuantity(cartonLists, paletteLists);
  const totalPaletteCount = paletteLists ? paletteLists.length : 0;
  const totalProducts = calculateTotalProducts(cartonLists, paletteLists);
  const totalProductsWithoutCarton = CalculateTotalProductWithoutCarton(paletteLists);
  const canPrint = items.every(item => item.ActualQuantity === 0);
  if (canPrint && !successSoundPlayed) {
    playSuccessSound();
    successSoundPlayed = true; 
  }


  function openPrintWindow(content: string) {
    const printWindow = window.open('', '', 'height=600,width=800');
    if (printWindow) {
      printWindow.document.write(content);
      printWindow.document.close();
      printWindow.print();

    } else {
      console.error('Failed to open print window.');
    }
  }

  const handleGlobalPrint = async () => {

    try {

    const sortItems = (items: Product[]) => {
      return items.sort((a, b) => {
        if (a.Bin_Code && b.Bin_Code) {
          return a.Bin_Code.localeCompare(b.Bin_Code);
        }
        return 0;
      });
    };
  
    const sortedCartonLists = allContent.cartonLists.map(cartonList => {
      const sortedItems = sortItems(cartonList.items);
      return {
        ...cartonList,
        items: sortedItems,
      };
    });
  
    const sortedPaletteLists = allContent.paletteLists.map(paletteList => {
      if ('items' in paletteList) {
        const sortedItems = paletteList.items.map(item => {
          if ('items' in item) {
            const sortedSubItems = sortItems(item.items);
            return {
              ...item,
              items: sortedSubItems,
            };
          }
          return item;
        }).sort((a, b) => {
          const aBinCode = 'Bin_Code' in a ? (a as Product).Bin_Code : undefined;
          const bBinCode = 'Bin_Code' in b ? (b as Product).Bin_Code : undefined;
          if (aBinCode && bBinCode) {
            return aBinCode.localeCompare(bBinCode);
          }
          return 0;
        });
        return {
          ...paletteList,
          items: sortedItems,
        };
      }
      return paletteList;
    });
  
    setAllContent({
      cartonLists: sortedCartonLists,
      paletteLists: sortedPaletteLists,
    });
    
    const isProduct = (item: Product | Carton): item is Product => {
      return (item as Product).Quantity !== undefined && (item as Product).ItemNetWeight !== undefined;
    };
    
    const isCarton = (item: Product | Carton): item is Carton => {
      return (item as Carton).totalPoidsCarton !== undefined;
    };
    
    const calculateTotalWeight = (cartonLists: Carton[], paletteLists: Palette[]): number | string => {
      let totalWeight = 0;
    
      cartonLists.forEach(carton => {
        totalWeight += carton.totalPoidsCarton ?? 0;
      });
    
      paletteLists.forEach(palette => {
        palette.items.forEach(item => {
          if (isCarton(item)) {
            totalWeight += item.totalPoidsCarton ?? 0;
          } else if (isProduct(item)) {
            totalWeight += item.Quantity * item.ItemNetWeight;
          }
        });
      });
    
      return totalWeight !== 0 ? totalWeight : 'N/A';
    };
    
    const totalWeight = calculateTotalWeight(cartonLists, paletteLists);
    

    // let totalWeight = 0;
    // items.forEach(item => {
    //   totalWeight += item.Quantity * item.ItemNetWeight;
    // });
  
    // totalWeight = parseFloat(totalWeight.toFixed(2));
  
    const destinationNo = items.length > 0 ? items[0].Destination_No ?? '' : '';
    const userInitials = user && user.displayName ? user.displayName
    .split(' ') 
    .map(word => word.charAt(0).toUpperCase()) 
    .join('.') 
    : 'Non renseigné';
    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day}${month}${year}${hours}${minutes}`;
    };
  
    const date = new Date();
    const formattedDate = formatDate(date);
  
    const formatDateDisplay = (date: Date): string => {
      const padToTwoDigits = (num: number): string => num.toString().padStart(2, '0');
  
      const day = padToTwoDigits(date.getDate());
      const month = padToTwoDigits(date.getMonth() + 1);
      const year = date.getFullYear();
      const hours = padToTwoDigits(date.getHours());
      const minutes = padToTwoDigits(date.getMinutes());
  
      return `${day}/${month}/${year} ${hours}h${minutes}`;
    };
  
    const formatDateDisplay2 = formatDateDisplay(date);
  
    const printContent = `
    <page>
        <style>
      @page {
        size: A4;
        margin: 20mm;
      }
  
      body { font-family: Arial, sans-serif; }
      h1 { color: #E4007C; text-align: center; }
      h2 { color: #E4007C; }
      table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
      table, th, td { border: solid 1mm #000000; }
      th, td { padding: 8px; text-align: left; }
      th { background-color: #f2f2f2; }
      .centered { text-align: center; }
      .logoPDF { text-align: left; }
  
    </style>
      <div class="logoPDF">
        <img src="/LogoTiloliClassic.png" alt="Logo" style="width: 100px;">
      </div>
      <h1>Packing list : ${destinationNo}_${formattedDate}</h1>
      <h4>Date: ${formatDateDisplay2}</h4>
      <h4>Numéro Client: ${destinationNo}</h4>
      <h4>Opérateur: ${userInitials}</h4> 
      <h2>Récapitulatif</h2>
      <table>
        <thead>
          <tr>
            <th class="centered">Total Produits</th>
            <th class="centered">Total Cartons</th>
            <th class="centered">Total Produits sans carton</th>
            <th class="centered">Total Palettes</th>
            <th class="centered">Total Poids</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="centered">${totalProducts}</td>
            <td class="centered">${totalCartonQuantity}</td>
            <td class="centered">${totalProductsWithoutCarton}</td>
            <td class="centered">${totalPaletteCount}</td>
            <td class="centered">${typeof totalWeight === 'number' ? totalWeight + ' kg' : totalWeight}</td>
          </tr>
        </tbody>
      </table>
      
      ${allContent.cartonLists.some(carton => carton.items.length > 0) ? `
        <h2>Mis en Carton/Palette</h2>
        ${allContent.cartonLists.map(carton => carton.items.length > 0 ? `
          <div class="no-break">
          <h3>
            ${carton.name}
            ${carton.totalPoidsCarton !== undefined && carton.totalPoidsCarton > 0 ? ` - ${carton.totalPoidsCarton} kg` : ''}
          </h3>
            <table>
              <thead>
                <tr>
                  <th>Num PO</th>
                  <th>Référence</th>
                  <th>Code EAN</th>
                  <th>Description</th>
                  <th>Quantité</th>
                  ${showUnitCost ? `<th>Poids Unitaire Brut</th>` : ''}
                </tr>
              </thead>
              <tbody>
                ${carton.items.map(item => `
                  <tr>
                    <td>${item.External_Document_No}</td>
                    <td>${item.Item_No}</td>
                    <td>${item.Code_EAN}</td>
                    <td>${item.Description}</td>
                    <td>${item.Quantity}</td>
                    ${showUnitCost ? `<td>${item.ItemNetWeight}</td>` : ''}

                  </tr>
                `).join('')}
              </tbody>
            </table>
          </div>
        ` : '').join('')}
      ` : ''}
  
      ${allContent.paletteLists.some(palette => palette.items.length > 0) ? `
        ${allContent.paletteLists.map(palette => palette.items.length > 0 ? `
          <div class="no-break">
            <h3>${palette.name}</h3>
            ${Array.isArray(palette.items) ? `
              <table>
                <thead>
                  <tr>
                    <th>Num PO</th>
                    <th>SKU</th>
                    <th>Code EAN</th>
                    <th>Description</th>
                    <th>Quantité</th>
                  ${showUnitCost ? `<th>Poids Unitaire Brut</th>` : ''}
                  </tr>
                </thead>
                <tbody>
                  ${palette.items.map(item => `
                    ${'items' in item ? `
                      <tr>
                        <td colspan="3">
                          <strong>
                            ${item.name} 
                            ${item.totalPoidsCarton !== undefined && item.totalPoidsCarton > 0 ? `${item.totalPoidsCarton} kg` : ''}                        </td>                      </tr>
                      ${item.items.map(subItem => `
                        <tr>
                          <td>${subItem.External_Document_No}</td>
                          <td>${subItem.Item_No}</td>
                          <td>${subItem.Code_EAN}</td>
                          <td>${subItem.Description}</td>
                          <td>${subItem.Quantity}</td>
                    ${showUnitCost ? `<td>${subItem.ItemNetWeight}</td>` : ''}
                        </tr>
                      `).join('')}
                    ` : `
                      <tr>
                        <td>${item.External_Document_No}</td>
                        <td>${item.Item_No}</td>
                        <td>${item.Code_EAN}</td>
                        <td>${item.Description}</td>
                        <td>${item.Quantity}</td>
                    ${showUnitCost ? `<td>${item.ItemNetWeight}</td>` : ''}
                      </tr>
                    `}
                  `).join('')}
                </tbody>
              </table>
            ` : ''}
          </div>
        ` : '').join('')}
      ` : ''}
    </page>
  `;
  
    openPrintWindow(printContent);
  
    const filename = `${destinationNo}_${formattedDate}_GlobalPackingList.pdf`;
    // await saveAsPdf({ printContent, fileName });
    await generatePdfWithPagination({ filename, content: printContent });    
  } catch (error) {
    console.error('Error during print process:', error); 
  }
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#E4007C' }}>
      <Toolbar style={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="center">
          <img src="/tiloliLogo.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Packing List Generator
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" style={{ flex: 1, marginLeft: '20px', marginRight: '20px' }}>
          <Typography style={{ color: 'white', marginRight: '10px' }}>{totalInitialQuantity-totalUpdatedQuantity}</Typography>
          <LinearProgress
            variant="determinate"
            value={progressionFigures}
            style={{ width: '100%', height: '20px', borderRadius: '5px' }}
          />
          <Typography style={{ color: 'white', marginLeft: '10px' }}>{totalInitialQuantity}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Client: {items.length > 0 ? items[0].Destination_No : ''}
        </Typography>
        
          <Tooltip title="Number of BPs">
            <Box display="flex" alignItems="center" style={{ marginLeft: '20px' }}>
              <LocalShippingIcon style={{ marginRight: '5px', color: 'white' }} />
              <Typography style={{ color: 'white' }}>{statistics.bpCount}</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Number of Different Items">
            <Box display="flex" alignItems="center" style={{ marginLeft: '20px' }}>
              <InventoryIcon style={{ marginRight: '5px', color: 'white' }} />
              <Typography style={{ color: 'white' }}>{statistics.itemCount}</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Total Quantity of Items">
            <Box display="flex" alignItems="center" style={{ marginLeft: '20px' }}>
              <QuantitiesIcon style={{ marginRight: '5px', color: 'white' }} />
              <Typography style={{ color: 'white' }}>{statistics.totalQuantity}</Typography>
            </Box>
          </Tooltip>
          {canPrint && (
        <Button
          variant="contained"
          style={{ backgroundColor: 'rgb(167, 202, 237)', color: 'white', fontWeight: 'bold', marginLeft: '20px' }}
          onClick={async () => {
            await handleGlobalPrint();
          }}
        >
          Impression Globale
        </Button>
      )}
          <Button
            variant="contained"
            style={{ backgroundColor: 'rgb(167, 202, 237)', color: 'white', fontWeight: 'bold', marginLeft: '20px' }}
            onClick={handleOpen}
          >
            Nouvelle Liste
          </Button>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: 400, 
            bgcolor: 'background.paper', 
            border: '2px solid #000', 
            boxShadow: 24, 
            p: 4 
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Êtes-vous sûr de vouloir tout réinitialiser ?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={() => handleReset(handleClose)}>              Oui
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Non
            </Button>
          </Box>
        </Box>
      </Modal>
        </Box>
      </Toolbar>
    </AppBar>
    
  );
};

export default Header;
