import React, {  useState, useEffect, useMemo, useCallback } from 'react';
import './App.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SendIcon from '@mui/icons-material/Send';
import { Print as PrintIcon, Delete as DeleteIcon, Restore as RestoreIcon, ShoppingBasket as ShoppingBasketIcon, AddShoppingCart as AddShoppingCartIcon } from '@mui/icons-material';

import { Product } from './interfaces';
import { useAppContext } from './AppContext';


const generatePastelColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  const saturation = 40;
  const lightness = 75;
  const alpha = 0.6;

  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
};


const ProductList: React.FC = () => {
  const [deleteItem, setDeleteItem] = useState<Product | null>(null);
  const [deleteQuantity, setDeleteQuantity] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [openSendModal, setOpenSendModal] = useState(false);
  const [sendItem, setSendItem] = useState<Product | null>(null);
  const [sendQuantity, setSendQuantity] = useState(1);
  const [selectedDestination, setSelectedDestination] = useState('');

  const { items, setItems, setReceivedData, combinedList, removedItems, setRemovedItems, DeletionProductInCarton } = useAppContext();
  const [lastModifiedItemId, setLastModifiedItemId] = useState<string | null>(null);
  
  const checkAndUpdateStatus = useCallback(() => {
    let hasChanges = false;
    const updatedItems: Product[] = items.map(item => {
        console.log('[product.tsx] Statut de l\'élément : ', item);
        
        if (item.ActualQuantity === 0 && item.Status !== 'Traité') {
            hasChanges = true;
            console.log('[product.tsx] Changement de statut de l\'élément à "Traité"');
            return { ...item, Status: 'Traité' };
        } else if (item.ActualQuantity > 0 && item.Status !== 'En cours') {
            hasChanges = true;
            console.log('[product.tsx] Changement de statut de l\'élément à "En cours"');
            return { ...item, Status: 'En cours' };
        }
        return item;
    });
    if (hasChanges) {
        setItems(updatedItems);
    } 
}, [items, setItems]);


  useEffect(() => {
    checkAndUpdateStatus();
  }, [items, checkAndUpdateStatus]);



  
  const handleDelete = (item: Product, quantityToDelete: number) => {
    setDeleteItem(item);
    setDeleteQuantity(quantityToDelete);
    setOpenDeleteModal(true);
  };


  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteItem(null);
    setDeleteQuantity(1);
  };

  const handleOpenSendModal = (item: Product, QuantityToSend:number) => {
    setSendItem(item);
    setSendQuantity(QuantityToSend);
    setSelectedDestination('');
    setOpenSendModal(true);
  };

  const handleCloseSendModal = () => {
    setOpenSendModal(false);
    setSendItem(null);
    setSendQuantity(1);
    setSelectedDestination('');
  };

  const handleSend = () => {
    console.log('[product.tsx] Début de la fonction handleSend');
    
    if (!sendItem || !selectedDestination) return;
  
    const { id, Item_No, Code_EAN, ActualQuantity, Description, ItemNetWeight, External_Document_No, Bin_Code } = sendItem; 
    const quantityToSend = sendQuantity;
    const destination = selectedDestination;
  
    if (ActualQuantity === 0) {
      console.log("[product.tsx] Impossible d'envoyer ce produit en carton car la quantité est nulle.");
      alert("Impossible d'envoyer ce produit en carton car la quantité est nulle.");
      return;
    }
    
    if (quantityToSend > ActualQuantity) {
      console.log("[product.tsx] La quantité à envoyer est supérieure à la quantité actualisée.");
      alert("La quantité à envoyer est supérieure à la quantité actualisée.");
      return;
    }
  
    const sendData = {
      id, 
      Item_No: Item_No,
      codeEAN: Code_EAN,
      quantity: quantityToSend,
      destination: destination,
      description: Description,
      ItemNetWeight: ItemNetWeight,
      External_Document_No: External_Document_No,
      Bin_Code: Bin_Code
    };
    console.log('[product.tsx] Produit envoyé :', sendData);

    setReceivedData(sendData);
    const updatedQuantity = ActualQuantity - quantityToSend;
    const updatedSendItem = { ...sendItem, ActualQuantity: updatedQuantity, PreviousActualQuantity: ActualQuantity 
    };
    const updatedItems = items.map(item => item.id === updatedSendItem.id ? updatedSendItem : item);
    setItems(updatedItems);
  
    handleCloseSendModal();
};

const handleConfirmDelete = () => {
    console.log('[product.tsx] Début de la fonction handleConfirmDelete');
    
    if (!deleteItem) return;
  
    const actualDeletedQuantity = Math.min(deleteQuantity, deleteItem.Quantity);
    let updatedItems: Product[] = [];
    
    items.forEach(item => {
      if (item.id === deleteItem.id) {
        const newQuantity = item.Quantity - actualDeletedQuantity;
        const newActualQuantity = item.ActualQuantity - actualDeletedQuantity;
  
        if (newQuantity > 0) {
          updatedItems.push({
            ...item,
            Quantity: newQuantity,
            ActualQuantity: newActualQuantity,
            PreviousActualQuantity: item.ActualQuantity
          });
        } else {
          updatedItems.push({
            ...item,
            Quantity: newQuantity,
            ActualQuantity: newActualQuantity,
            PreviousActualQuantity: item.ActualQuantity
          });
        }
      } else {
        updatedItems.push(item);
      }
    });
    console.log('[product.tsx]  handleConfirmDelete item maj :', updatedItems);

    setItems(updatedItems);
    const updatedRemovedItems = [...removedItems, { ...deleteItem, Quantity: actualDeletedQuantity }];
    setRemovedItems(updatedRemovedItems);
    handleCloseDeleteModal();
};

  

  useEffect(() => {
    if (DeletionProductInCarton) {
      console.log(`[product.tsx] Modification de l'item avec l'id ${DeletionProductInCarton.id} - Ajout de la quantité ${DeletionProductInCarton.quantity}`);
      setItems(prevItems => {
        const updatedItems = prevItems.map(item => {
          if (item.id === DeletionProductInCarton.id) {
            const updatedQuantity = (item.ActualQuantity || 0) + DeletionProductInCarton.quantity;
            return {
              ...item,
              ActualQuantity: updatedQuantity,
              PreviousActualQuantity: item.ActualQuantity
            };
          }
          return item;
        });
        return updatedItems;
      });
    }
  }, [DeletionProductInCarton, setItems]);
  
  
  const handleRowColor = (blNumber: string, item: Product | undefined): string => {
    if (item && item.id === lastModifiedItemId) {
      return '#68ff7a';
    } else {
      return generatePastelColor(blNumber);
    }
  };

  const handlePrintLists = (items: Product[], listName: string) => {
    const printWindow = window.open('', '', 'height=600,width=800');
    if (printWindow) {
      const printDocument = printWindow.document;
      printDocument.write('<html><head><title>Print</title>');
      printDocument.write('</head><body>');
      printDocument.write(`<div style="padding: 20px;"><h2>${listName}</h2></div>`);
      items.forEach(item => {
        printDocument.write('<div style="padding: 20px;">');
        printDocument.write(`<p>Référence: <strong>${item.Item_No}</strong></p>`);
        printDocument.write(`<p>Description: ${item.Description}</p>`);
        printDocument.write(`<p>Code EAN: ${item.Code_EAN}</p>`);
        printDocument.write(`<p>Poids Net: ${item.ItemNetWeight}</p>`);
        printDocument.write(`<p>Quantité: ${item.Quantity}</p>`);
        printDocument.write(`<p>NoBl: ${item.No}</p>`);
        printDocument.write(`<p>Statut: ${item.Status}</p>`);
        printDocument.write('</div>');
      });
      printDocument.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
    }
  };


  
  useEffect(() => {
    setRemovedItems(removedItems);
  }, [removedItems, setRemovedItems]);

  const [, setHoveredRow] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const sortedItemsBinCode = useMemo(() => {
    return items.sort((a, b) => {
      if (a.Bin_Code && b.Bin_Code) {
        return a.Bin_Code.localeCompare(b.Bin_Code);
      }
      return 0;
    });
  }, [items]);


  useEffect(() => {
    const itemWithChangedQuantity = items.find(item => item.ActualQuantity !== item.PreviousActualQuantity);
    if (itemWithChangedQuantity) {
      setLastModifiedItemId(itemWithChangedQuantity.id); 
      const updatedItems = items.map(item => {
        if (item.id === itemWithChangedQuantity.id) {
          return {
            ...item,
            PreviousActualQuantity: item.ActualQuantity,
          };
        }
        return item;
      });
      setItems(updatedItems);
    } 
  }, [items,setItems]);

  const treatedItems = items.filter(item => item.Status === 'Traité').reverse();



  const handleRestore = (item: Product) => {
    const index = removedItems.findIndex(removedItem => removedItem.id === item.id);
    const existingItemIndex = items.findIndex(i => i.id === item.id);

    let updatedItems;

    if (existingItemIndex !== -1) {
      const updatedItem = { ...items[existingItemIndex] };
      updatedItem.ActualQuantity += item.Quantity;
      updatedItem.Quantity += item.Quantity;

      console.log(`[product.tsx] Restauration de l'item avec l'id ${item.id} - Quantité ajoutée : ${item.Quantity}`);

      updatedItems = [
        ...items.slice(0, existingItemIndex),
        updatedItem,
        ...items.slice(existingItemIndex + 1)
      ];
    } else {
      console.log(`[product.tsx] Ajout d'un nouvel item avec l'id ${item.id}`);

      updatedItems = [...items, { ...item, Quantity: item.Quantity }];
    }

    setItems(updatedItems);

    const updatedRemovedItems = [...removedItems];
    updatedRemovedItems.splice(index, 1);

    console.log(`[product.tsx] Retrait de l'item avec l'id ${item.id} de la liste des items supprimés`);

    setRemovedItems(updatedRemovedItems);
};


  useEffect(() => {
    const idMap: { [key: string]: { Code_EAN: string; No: string }[] } = {};

    items.forEach((product) => {
      const { id, Code_EAN, No } = product;
      if (!idMap[id]) {
        idMap[id] = [];
      }
      idMap[id].push({ Code_EAN: Code_EAN || '', No: No || '' });
    });

    Object.keys(idMap).forEach(id => {
      if (idMap[id].length > 1) {
        const uniqueOccurrences = Array.from(new Set(idMap[id].map(({ Code_EAN, No }) => `${Code_EAN}, ${No}`)))
                                      .join(' and ');

        console.log(`Duplicate ID detected: ${id}. View with ${uniqueOccurrences}`);
      }
    });
  }, [items]);


  return (
    <div className="productlist">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">Liste Produit à traiter</Typography>
        <IconButton aria-label="print" onClick={() => handlePrintLists(items.filter(item => item.Status !== 'Traité'), 'Produits traités')}>    
        <PrintIcon />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
  <Table className="custom-table" size="small">
    <TableHead>
      <TableRow>
        <TableCell align="center">Reference</TableCell>
        <TableCell align="center">Description</TableCell>
        <TableCell align="center">Code EAN</TableCell>
        <TableCell align="center"><ShoppingBasketIcon /></TableCell>
        <TableCell align="center">
            <AddShoppingCartIcon />
        </TableCell>
        <TableCell align="center">NºBP</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {sortedItemsBinCode.filter(item => item.Status !== 'Traité').map((item, index) => (
        
        <Fade in={true} timeout={1000} key={index}>
          <TableRow onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave} style={{
              backgroundColor: handleRowColor(item.No, item)
            }}>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.6em', whiteSpace: 'nowrap', maxWidth: '5ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip title={item.Item_No} arrow>
              <span><strong>{item.Item_No}</strong></span>
            </Tooltip>
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em', whiteSpace: 'nowrap', maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Tooltip title={item.Description} arrow>
                <span>{item.Description.length > 12 ? `${item.Description.slice(0, 12)}...` : item.Description}</span>
              </Tooltip>
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.6em', maxWidth: '5ch', wordBreak: 'break-all' }}>
              {item.Code_EAN}
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
              {item.ItemNetWeight}
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
              {`${item.ActualQuantity}/${item.Quantity}`}
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
            <Tooltip title={item.Bin_Code || ''} arrow>
                      <span>{item.No}</span>
                    </Tooltip>
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
              <IconButton aria-label="delete" onClick={() => handleDelete(item, item.ActualQuantity)} style={{ padding: '0px' }}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              <IconButton aria-label="send" onClick={() => handleOpenSendModal(item, item.ActualQuantity)} style={{ padding: '0px' }}>
                <SendIcon fontSize="small" />
              </IconButton>
            </TableCell>

          </TableRow>
        </Fade>
      ))}
    </TableBody>
  </Table>
</TableContainer>

<div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">Liste Produit traité</Typography>
        <IconButton aria-label="print" onClick={() => handlePrintLists(treatedItems, 'Produits traités')}>
          <PrintIcon />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Reference</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Code EAN</TableCell>
              <TableCell align="center"><ShoppingBasketIcon /></TableCell>
              <TableCell align="center">
                <AddShoppingCartIcon />
              </TableCell>
              <TableCell align="center">NoBl</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {treatedItems.map((item, index) => (
              <TableRow
              key={index}
              // style={{
              //   backgroundColor: index === 0 ? '#b5f5d4' : 'inherit', 
              // }}
              >
                <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.6em', whiteSpace: 'nowrap', maxWidth: '5ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Tooltip title={item.Item_No} arrow>
                    <span><strong>{item.Item_No}</strong></span>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em', whiteSpace: 'nowrap', maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Tooltip title={item.Description} arrow>
                    <span>{item.Description.length > 12 ? `${item.Description.slice(0, 12)}...` : item.Description}</span>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.6em', maxWidth: '5ch', wordBreak: 'break-all' }}>
                  {item.Code_EAN}
                </TableCell>
                <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
                  {item.ItemNetWeight}
                </TableCell>
                <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
                  {`${item.Quantity}`}
                </TableCell>
                <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
                  {item.No}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6">Produits retirés</Typography>
      <IconButton aria-label="print" onClick={() => handlePrintLists(removedItems, 'Produits retirés')}>
        <PrintIcon />
      </IconButton>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
            <TableCell align="center">Reference</TableCell>
        <TableCell align="center">Description</TableCell>
        <TableCell align="center">Code EAN</TableCell>
        <TableCell align="center"><ShoppingBasketIcon /></TableCell>
        <TableCell align="center">
            <AddShoppingCartIcon />
        </TableCell>
        <TableCell align="center">NoBl</TableCell>
        <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {removedItems.map((item, index) => (
              <TableRow key={index}>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.6em', whiteSpace: 'nowrap', maxWidth: '5ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Tooltip title={item.Item_No} arrow>
              <span><strong>{item.Item_No}</strong></span>
            </Tooltip>
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em', whiteSpace: 'nowrap', maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Tooltip title={item.Description} arrow>
                <span>{item.Description.length > 12 ? `${item.Description.slice(0, 12)}...` : item.Description}</span>
              </Tooltip>
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.6em', maxWidth: '5ch', wordBreak: 'break-all' }}>
              {item.Code_EAN}
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
              {item.ItemNetWeight}
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
              {`${item.Quantity}`}
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
              {item.No}
            </TableCell>
            <TableCell style={{ padding: '0px', textAlign: 'center', fontSize: '0.8em' }}>
            <IconButton aria-label="restore" onClick={() => handleRestore(item)} style={{ padding: '0px' }}>
                    <RestoreIcon fontSize="small" />
                  </IconButton>                
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
        <Typography id="modal-description" sx={{ mt: 2 }}>
            <IconButton onClick={() => setDeleteQuantity(prev => Math.min(prev + 1, deleteItem?.ActualQuantity || 1))}>
              <AddIcon />
            </IconButton>
            {deleteQuantity}
            <IconButton onClick={() => setDeleteQuantity(prev => Math.max(1, prev - 1))}>
              <RemoveIcon />
            </IconButton>
            unités de {deleteItem?.Item_No} ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Annuler</Button>
          <Button onClick={handleConfirmDelete} color="error">Supprimer</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSendModal} onClose={handleCloseSendModal}>
        <DialogTitle>Envoyer Produit</DialogTitle>
        <DialogContent>
          <Typography>
            Vous voulez envoyer :
          </Typography>
          <div className="center-content-modal">
          {/* <IconButton onClick={() => setSendQuantity(prev => Math.min(prev + 1, sendItem?.ActualQuantity || 1))}><AddIcon /></IconButton> */}
          <TextField
          value={sendQuantity}
          onChange={(e) => setSendQuantity(Number(e.target.value))}
          type="number"
          inputProps={{ min: 1, max: sendItem?.ActualQuantity || 1 }}
        /></div>
          {/* <IconButton onClick={() => setSendQuantity(prev => Math.max(1, prev - 1))}><RemoveIcon /></IconButton> */}
          <FormControl fullWidth>
            <InputLabel id="destination-label">Destination</InputLabel>
            <Select
              labelId="destination-label"
              value={selectedDestination}
              onChange={(e) => setSelectedDestination(e.target.value as string)}
            >
              {combinedList.map((destination, index) => (
                <MenuItem key={index} value={destination}>{destination}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSendModal}>Annuler</Button>
          <Button onClick={handleSend} color="primary">Envoyer</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ProductList;
